/*
Codes used 
    background: rgba(139, 56, 227, 0.05);
background: #202020
for text head :     color: #7f47b9;
for describe text :     color: #bea0dd;

*/
[mode="dark"] body {
  /* background: #120f0f; */
  background: #1c1c1c;
  /* overflow-y: hidden; */
}

[mode="dark"] .custom-dropdown {
  color: #000 !important;
}

[mode="dark"] .contact-us-btn:hover {
  background: rgba(7, 5, 5, 0.6);
}
/* [mode="dark"]#responsive-navbar-nav .nav-link.nav-transparent, .nav-transparent > a
{
  color: #000 !important;
} */
[mode="dark"] #responsive-navbar-nav .nav-link.nav-white
{
  color: #ffffff !important;
}
[mode="dark"] .nav-white > a {
  color: #ffffff !important;
}

/* =========================  start home page  ========================= */
/* ========================= abous us  ========================= */

[mode="dark"] .about-us-container .about-us-info p:nth-of-type(1) {
  color: #7f47b9;
}
[mode="dark"] .about-us-container .about-us-info p:nth-of-type(2) {
  color: #bea0dd;
}
/* ========================= Clients ========================= */
[mode="dark"] .our-clients {
  background-color: #202020;
}

/* ========================= Solutions-HomePage ========================= */

[mode="dark"] .solutions {
  background: #202020;
}
[mode="dark"] .solutions .container > p {
  color: #7f47b9;
}
[mode="dark"] .solutions .solutions-cards .solutions-card > p {
  color: #bea0dd;
}
[mode="dark"] .solutions .solutions-cards .solutions-card > h4 {
  color: #ffffff;
}

/* ========================= Our_Services ========================= */
[mode="dark"] .our-services {
  background: #202020;
}

[mode="dark"] .our-services .container > p {
  color: #7f47b9;
}

[mode="dark"] .service-card > h4 {
  color: #ffffff;
}

[mode="dark"] .service-card > p {
  color: #bea0dd;
}

/* =========================  End home page  ========================= */

/* =========================  start about us page  ========================= */
/* =========================   about us  ========================= */
[mode="dark"] .about-us-container.page {
  background: #141010;
}

/* =========================   Why us  ========================= */
[mode="dark"] .why-us-section {
  background: #202020;
}
[mode="dark"] .why-us-section h2 {
  color: #7f47b9;
}
[mode="dark"] .why-us-card p {
  color: #bea0dd;
}

[mode="dark"] .card-bg-1,
[mode="dark"] .card-bg-2,
[mode="dark"] .card-bg-3 {
  background: rgba(139, 56, 227, 0.05);
}

/* =========================   our access story  ========================= */
[mode="dark"] .success-story-header h2 {
  color: #7f47b9;
}
[mode="dark"] .milestone-section .milestones-header > h3 {
  color: #ffffff;
}
[mode="dark"] .milestone-section .milestones-header > p {
  color: #bea0dd;
}
[mode="dark"] .milestone-section .milestones .milestone-info .header > p {
  color: #ffffff;
}
[mode="dark"] .milestone-section .milestones .milestone-info .points p {
  color: #bea0dd;
}
/* =========================  start services page  ========================= */
/* =========================   Services  ========================= */
[mode="dark"] .services-section h2 {
  color: #f1e5e5;
}
[mode="dark"] .services-section p {
  color: #bea0dd;
}
[mode="dark"] .services-card h3 {
  color: #ffffff;
}
/* =========================   Business Solution  ========================= */
[mode="dark"] .service-title h2 {
  color: #7f47b9;
}
[mode="dark"] .service-details-wrapper p {
  color: #bea0dd;
}
/* =========================  start solutions page  ========================= */
/* =========================  What we provide  ========================= */
[mode="dark"] .solutions-page .what-we-provide .container > p:first-of-type {
  color: #7f47b9;
}
[mode="dark"] .solutions-page .what-we-provide .container > p:nth-of-type(2) {
  color: #bea0dd;
}
[mode="dark"] .solutions-page .what-we-provide .container .provide-cards .provide-card > p {
  color: #ffffff;
}
/* =========================  Our Sectors  ========================= */
[mode="dark"] .solutions-page .sectors .container .header > p:first-of-type {
  color: #7f47b9;
}
[mode="dark"] .solutions-page .sectors .container .header > P:nth-of-type(2) {
  color: #bea0dd;
}
[mode="dark"] .solutions-page .sectors .container .sectors-wrapper .sector-card h4 {
  color: #ffffff;
}
/* =========================  start success stories page  ========================= */
/* =========================  projects  ========================= */
[mode="dark"] .projects-page .projects-header > h3 {
  color: #ffffff;
}
[mode="dark"] .projects-page .projects-header > p {
  color: #bea0dd;
}
[mode="dark"] .projects-page .project-card {
  background: rgba(139, 56, 227, 0.05);
}
[mode="dark"] .projects-page .project-card > h4 {
  color: #ffffff;
}
[mode="dark"] .projects-page .project-card .project-Desc {
  color: #bea0dd;
}
[mode="dark"] .projects-page .project-card .project-client {
  color: #989393;
}
/* =========================  Clients & Partners  ========================= */
[mode="dark"] .contact h2 {
  color: #ffffff;
}
[mode="dark"] .contact span {
  color: #bea0dd;
}
[mode="dark"] .clients__Oracle,
[mode="dark"] .clients__amana,
[mode="dark"] .clients__1427,
[mode="dark"] .clients__realState,
[mode="dark"] .clients__TGA,
[mode="dark"] .clients__stc,
[mode="dark"] .clients__Davita,
[mode="dark"] .clients__Zoom {
  background-color: #323232;
}
[mode="dark"] .clients__IndustrialModern,
[mode="dark"] .clients__IntegratedDawiyat,
[mode="dark"] .clients__mcv {
  background-color: #433c3c;
}
/* =========================  Certificates  ========================= */
[mode="dark"] .certificates-section .certificates-header p {
  color: #bea0dd;
}
[mode="dark"] .certificate-card {
  background: rgba(139, 56, 227, 0.05);
  color: #ffffff;
  border: 2px dashed #564f4f;
}
[mode="dark"] .certificates-section .certificates-header h2 {
  color: #ffffff;
}
/* =========================  fag's page  ========================= */

[mode="dark"] .faq-page {
  background: #1c1c1c;
}
[mode="dark"] .faq-header h3 {
  color: #ffffff;
}
[mode="dark"] .faq-header p {
  color: #bea0dd;
}
[mode="dark"] .accordion__holder {
  background: #232323;
}
[mode="dark"] .accordion-button {
background: #232323 !important;
}

[mode="dark"] .accordion__body {
  color: #818181;
}
[mode="dark"] .accordion__body button {
  color: #999999;
}

[mode="dark"] .inquiry-form-text h4 {
  color: #ffffff;
}
[mode="dark"] .inquiry-form-text p {
  color: #bea0dd;
}
[mode="dark"] .inquiry-form-container {
  background: #433d3d;
}
[mode="dark"] .inquiry-form-container input {
  background: #6a6174;
}

[mode="dark"] .accordion__body {
  background-color: #232323;
}
[mode="dark"] .accordion__body button:not(.collapsed) {
  color: #ffffff;
}
/* =========================  contact us page  ========================= */

[mode="dark"] .contact h2 {
  color: #ffffff;
}
[mode="dark"] .addresses {
  background-color: #202020;
}
[mode="dark"] .contact h3 {
  color: #bea0dd;
}
[mode="dark"] .bg__KSA,
[mode="dark"] .bg__Egypt,
[mode="dark"] .bg__UAE,
[mode="dark"] .bg__Bahrain,
[mode="dark"] .bg__Germany,
[mode="dark"] .bg__Italy {
  background-color: rgb(3 142 92 / 5%);
}
[mode="dark"] .country__holder .country {
  color: #ffffff;
}
[mode="dark"] .countries .branchName .branch {
  color: #b5b5b5;
}
[mode="dark"] .countries .branchName {
  color: #757575;
}
[mode="dark"] .input-container {
  background: #2c2a2a;
}
[mode="dark"] .input-container label {
  color: #bea0dd;
}
[mode="dark"] .input-wrapper input.name-input
{
  background: #2f2f2f;
}
[mode="dark"] .input-wrapper input.email-input{
  background: #2f2f2f;
}
[mode="dark"] .input-wrapper textarea.message-input {
  background: #2f2f2f;
}

[mode="dark"] .input-wrapper input {
  border-color: #707070;
}
[mode="dark"] .input-container textarea {
  border-color: #707070;
}
[mode="dark"] .btn-to-top {

  background-color: #160329;

}
[mode="dark"] .accordion__Item {
  border-bottom: solid 1px #5a5a5a  !important;
}
[mode="dark"] .clients__BordersTop {
  border: 1px solid #5c5c5c;
}
[mode="dark"] .clients__BordersBottom {
  border: 1px solid #5c5c5c;
}
[mode="dark"] .clients__holder {
  border: 1px solid #5c5c5c;
}

[mode="dark"] .org-section h1 {
  color: #f1e5e5;
}
[mode="dark"] .org-section p {
  color: #bea0dd;
}
[mode="dark"] .ceo-label::before {
  border: 2px solid #666666;
}
[mode="dark"] .ceo-label::after {
  border: 2px solid #666666;
}
[mode="dark"] .Administrative-label::before {
  border: 2px solid #666666;
}
[mode="dark"] .kkk::before {
  border: 2px solid #666666;
}
[mode="dark"] .kkk::after {
  border: 2px solid #666666;
}