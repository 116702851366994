/* [mode="light"] body {
  background: #eaf5fa;
} */

[mode="light"] .custom-dropdown {
  color: #000 !important;
}

/* =========================  start home page  ========================= */
/* ========================= abous us  ========================= */

[mode="light"] .about-us-container .about-us-info p:nth-of-type(1) {
  color: var(--Purple, #2e0757);
}

[mode="light"] .about-us-container .about-us-info p:nth-of-type(2) {
  color: var(--Body, #776a86);
}
/* ========================= Clients ========================= */
[mode="light"] .our-clients {
  background-color: white;
}
/* ========================= Solutions-HomePage ========================= */
[mode="light"] .solutions {
  background: var(--White, #fff);
}
[mode="light"] .solutions .container > p {
  color: var(--Purple, #2e0757);
}
[mode="light"] .solutions .solutions-cards .solutions-card > p {
  color: #705190;
}
[mode="light"] .solutions .solutions-cards .solutions-card > h4 {
  color: var(--Purple, #2e0757);
}

/* ========================= Our_Services ========================= */
[mode="light"] .our-services {
  background: var(--White, #fff);
}

[mode="light"] .our-services .container > p {
  color: var(--Black, #2e0757);
}

[mode="light"] .service-card > h4 {
  color: var(--Purple, #2e0757);
}
[mode="light"] .service-card > p {
  color: var(--Purple, #2e0757);
}
/* =========================  End home page  ========================= */

/* =========================  start about us page  ========================= */
/* =========================   about us  ========================= */
[mode="light"] .about-us-container.page {
  background: #eaf5fa;
}
/* =========================   Why us  ========================= */
[mode="light"] .why-us-section {
  background: #ffffff;
}
[mode="light"] .why-us-section h2 {
  color: #2e0757;
}
[mode="light"] .why-us-card p {
  color: #2e0757;
}

[mode="light"] .card-bg-1 {
  background: rgba(139, 56, 227, 0.05);
}

[mode="light"] .card-bg-2 {
  background: rgba(245, 249, 254);
}

[mode="light"] .card-bg-3 {
  background: rgba(245, 252, 247);
}

/* =========================   our access story  ========================= */
[mode="light"] .success-story-header h2 {
  color: #2e0757;
}
[mode="light"] .milestone-section .milestones-header > h3 {
  color: var(--Black, #2e0757);
}

[mode="light"] .milestone-section .milestones-header > p {
  color: var(--Body, #776a86);
}
[mode="light"] .milestone-section .milestones .milestone-info .header > p {
  color: var(--Black, #2e0757);
}
[mode="light"] .milestone-section .milestones .milestone-info .points p {
  color: #584c66;
}

/* =========================  start services page  ========================= */
/* =========================   Services  ========================= */
[mode="light"] .services-section h2 {
  color: #2e0757;
}
[mode="light"] .services-section p {
  color: #776a86;
}
[mode="light"] .services-card h3 {
  color: #2e0757;
}

/* =========================   Business Solution  ========================= */
[mode="light"] .service-title h2 {
  color: #2e0757;
}
[mode="light"] .service-details-wrapper p {
  color: #776a86;
}

/* =========================  start solutions page  ========================= */
/* =========================  What we provide  ========================= */
[mode="light"] .solutions-page .what-we-provide .container > p:first-of-type {
  color: var(--Purple, #2e0757);
}
[mode="light"] .solutions-page .what-we-provide .container > p:nth-of-type(2) {
  color: var(--Body, #776a86);
}
[mode="light"] .solutions-page .what-we-provide .container .provide-cards .provide-card > p {
  color: var(--Purple, #2e0757);
}
/* =========================  Our Sectors  ========================= */
[mode="light"] .solutions-page .sectors .container .header > p:first-of-type {
  color: var(--Black, #2e0757);
}
[mode="light"] .solutions-page .sectors .container .header > P:nth-of-type(2) {
  color: var(--Body, #776a86);
}
[mode="light"] .solutions-page .sectors .container .sectors-wrapper .sector-card h4 {
  color: var(--Black, #2e0757);
}
/* =========================  start success stories page  ========================= */
/* =========================  projects  ========================= */
[mode="light"] .projects-page .projects-header > h3 {
  color: var(--Black, #2e0757);
}
[mode="light"] .projects-page .projects-header > p {
  color: var(--Body, #776a86);
}
[mode="light"] .projects-page .project-card {
  background: #fff;
}
[mode="light"] .projects-page .project-card > h4 {
  color: var(--Black, #2e0757);
}
[mode="light"] .projects-page .project-card .project-Desc {
  color: var(--Body, #776a86);
}
[mode="light"] .projects-page .project-card .project-client {
  color: var(--Black, #2e0757);
}
/* =========================  Clients & Partners  ========================= */
[mode="light"] .contact h2 {
  color: var(--Black, #2e0757);
}
[mode="light"] .contact span {
  color: var(--Body, #776a86);
}
[mode="light"] .clients__Oracle,
[mode="light"] .clients__amana,
[mode="light"] .clients__1427,
[mode="light"] .clients__realState,
[mode="light"] .clients__TGA,
[mode="light"] .clients__stc,
[mode="light"] .clients__Davita,
[mode="light"] .clients__Zoom {
  background-color: #fbeff0;
}
[mode="light"] .clients__IndustrialModern,
[mode="light"] .clients__IntegratedDawiyat,
[mode="light"] .clients__mcv {
  background-color: #ebfdf8;
}
/* =========================  Certificates  ========================= */
[mode="light"] .certificates-section .certificates-header p {
  color: var(--Basic-Body, #666);
}
[mode="light"] .certificate-card {
  background: rgba(255, 255, 255, 0.6);
  border: 2px dashed #e3e3e3;
}
[mode="light"] .certificates-section .certificates-header h2 {
  background: linear-gradient(104.53deg, #96145ab3 1.82%, #320098b3 129.58%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* =========================  fag's page  ========================= */

[mode="light"] .faq-page {
  background: #fafdff;
}

[mode="light"] .faq-header h3 {
  color: #2e0757;
}
[mode="light"] .faq-header p {
  color: #776a86;
}
[mode="light"] .accordion__holder {
  background: #fff;
}
[mode="light"] .accordion__body {
  color: black;
}
[mode="light"] .accordion__body button {
  color: #0e1e33;
}
[mode="light"] .inquiry-form-text h4 {
  color: #2e0757;
}
[mode="light"] .inquiry-form-text p {
  color: #6a5b7a;
}
[mode="light"] .inquiry-form-container {
  background: #fff;
}
/* =========================  contact us page  ========================= */
[mode="light"] .contact h3 {
  color: var(--Body, #776a86);
}
[mode="light"] .bg__KSA {
  background-color: rgb(3 142 92 / 5%);
}

[mode="light"] .bg__Egypt {
  background-color: rgb(204 52 52 / 5%);
}

[mode="light"] .bg__UAE {
  background-color: rgb(59 204 55 / 5%);
}

[mode="light"] .bg__Bahrain {
  background-color: rgb(218 30 52 / 5%);
}

[mode="light"] .bg__Germany {
  background-color: rgb(248 209 46 / 5%);
}

[mode="light"] .bg__Italy {
  background-color: rgb(221 69 69 / 5%);
}
[mode="light"] .country__holder .country {
  color: var(--Black, #2e0757);
}
[mode="light"] .countries .branchName .branch {
  color: var(--Black, #2e0757);
}
[mode="light"] .countries .branchName {
  color: var(--Body, #776a86);
}
[mode="light"] .input-container {
  background: var(--White, #fff);
}
[mode="light"] .input-container label {
  color: var(--Body, #776a86);
}
[mode="light"] .btn-to-top {

  background-color: #7e02ff;

}
[mode="light"] .accordion__Item {
  border-bottom: solid 1px #d4d5dc !important;
}
[mode="light"] .clients__BordersTop {
  border: 1px solid #dee2ec;
}
[mode="light"] .clients__BordersBottom {
  border: 1px solid #dee2ec;
}
[mode="light"] .clients__holder {
  border: 1px solid #dee2ec;
}
[mode="light"] .input-wrapper input {
  border-color: #e0e0e0;
}
[mode="light"] .input-container textarea {
  border-color: #e0e0e0;
}
[mode="light"] .ceo-label::before {
  border: 2px solid #3E037C;
}
[mode="light"] .ceo-label::after {
  border: 2px solid #3E037C;
}
[mode="light"] .Administrative-label::before {
  border: 2px solid #3E037C;
}
[mode="light"] .kkk::before {
  border: 2px solid #3E037C;
}
[mode="light"] .kkk::after {
  border: 2px solid #3E037C;
}
[mode="light"] #responsive-navbar-nav .nav-link.nav-white
{
  color: var(--Purple, #2e0757);
}
[mode="light"] .nav-white > a {
  color: var(--Purple, #2e0757);
}